import React from "react"

import EduWorkButton from "./eduWorkButton"
import { useConfig } from "../../graphql/useConfig"

import * as styles from "./ctaEduWork.module.scss"

const CtaEduWork = ({ text, title }) => {
  const { workLink, eduLink } = useConfig()

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
      <div className={styles.buttons}>
        <EduWorkButton type="work" url={workLink} />
        <EduWorkButton type="edu" url={eduLink} />
      </div>
    </div>
  )
}

export default CtaEduWork
